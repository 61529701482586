import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import NewsLetter from "../components/NewsLetter"
import { Col, Row } from "react-bootstrap"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as containerStyles from "./blog-detail.module.css"

export default function BlogDetailTemplate({ pageContext }) {
  const { post } = pageContext

  const blogMeta = [
    {
      property: `og:type`,
      content: `article`,
    },
    {
      property: `article:published_time`,
      content: post.frontmatter.date,
    },
    {
      property: `article:author`,
      content: post.frontmatter.author,
    },
  ];

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
        meta={blogMeta}
        image={post.frontmatter.imagePath}
      />
      <div className={`py-5`}>
        <h1 className={`display-5 fw-bold`}>{post.frontmatter.title}</h1>
        <div className={`px-2`}>
          <Row className="align-items-center">
            <Col xs={2} lg="auto">
              <strong>By:</strong>
            </Col>
            <Col xs={10} lg={2}>
              {post.frontmatter.author}
            </Col>
            <Col xs={2} lg="auto">
              <strong>Date:</strong>
            </Col>
            <Col xs={10} lg="auto">
              {post.frontmatter.date}
            </Col>
          </Row>
          <div className={`${containerStyles.body} py-4`}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
        <NewsLetter />
      </div>
    </Layout>
  )
}
