
import React, { useState } from "react"
import { navigate } from "gatsby"
import { Button, Col, Form, Row } from 'react-bootstrap'

const NewsLetter = () => {

    let [email, setEmail] = useState('');
    let [disableButton, setDisabledSubmit] = useState(false);

    const submit = (event) => {
        event.preventDefault();
        setDisabledSubmit(true);
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            mode: 'no-cors',
            body: JSON.stringify({
                "company":"",
                "name":"",
                "email":email,
                "message":"Newsletter signup"
            })
        };

        fetch("https://formsubmithttp.azurewebsites.net/api/FormSubmitHTTP?code=ZPmNrdS0MEMSal166V0MB8nqpdaX9ReKy/1A4Gzs9LynTDba0mC4ag==", requestOptions)
            .then(response => {
                console.log('success'); 
                navigate('/form-submission/exclusive-content-thankyou');
            })
            .catch(error => {
                console.error('error occurred')
            });
    };

    return (
        <Form onSubmit={submit}>
            <Row className="justify-content-md-center align-items-center">
                <Col lg={6} className={`text-center`}><strong>If you like this then sign up for exclusive content</strong></Col>
                <Col lg={4}>
                    <Form.Control 
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                        type="email"></Form.Control>
                </Col>
                <Col className="d-flex justify-content-end" lg={1}>
                    <Button type="submit" disabled={disableButton}>Submit</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default NewsLetter